import React from 'react'
import Layout from '../components/layout'

class Qualitaetsstandards extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Qualitätsstandards | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Qualitätsstandards</h2>
              </header>
              <div>
                <h2>Worauf können Sie sich verlassen?</h2>
                <p>
                  Auf einen wertschätzenden und aktzeptierenden Umgang mit Ihnen
                  als Person, unabhängig von Ihren Problemen, Diagnosen, Ihrer
                  Lebenssituation oder Lebensgeschichte
                </p>
                <p>
                  Auf absolute Vertraulichkeit im Umgang mit Ihren Daten und
                  Informationen (Schweigepflicht)
                </p>
                <p>
                  Ich werde mich darum bemühen, Sie so gut und sorgfältig wie
                  möglich zu unterstützen.
                </p>
                <p>
                  Ich bin für Sie – soweit notwendig und vereinbart –
                  kurzfristig ansprechbar über e-mail, in meinen
                  Telefonsprechzeiten und per Handy / bzw. Rückrufbitte über
                  meinen Anrufbeantworter.
                </p>
                <p>
                  Ich bin als Psychologische Psychotherapeutin approbiert und im
                  Arztregister verzeichnet.
                </p>
                <p>
                  Ich erfülle die Qualifikationen und sozialrechtlichen
                  Voraussetzungen für die Abrechnung der Kosten mit gesetzlichen
                  und privaten Krankenversicherungsträgern.
                </p>
                <p>
                  Durch regelmäßige Fortbildung und Supervision gewährleiste ich
                  gute Qualität meiner Arbeit.
                </p>
                <p>
                  Darüberhinaus entwickle und reflektiere ich meine Tätigkeit
                  durch Umsetzung eines speiziell für psychotherapeutische
                  Praxen entwickelten Qualitätsmanagementsystems (QEP).
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Qualitaetsstandards
